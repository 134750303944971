// @import '~antd/dist/antd.less';
@import '~antd/dist/antd.less';
@import '@/assets/styles/reset.css';

#root {
  height: 100vh;
  background-color: #f1f2f5 !important;
  box-sizing: border-box;
  // /* 禁止鼠标直接选中 */
  // -webkit-touch-callout: none;
  // /*系统默认菜单被禁用*/
  // -moz-user-select: none;
  // /*火狐*/
  // -webkit-user-select: none;
  // /*webkit浏览器*/
  // -ms-user-select: none;
  // /*IE10*/
  // -khtml-user-select: none;
  // /*早期浏览器*/
  // user-select: none;
}

.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
  border-bottom-color: #c4c4c4 !important;
}

.ant-table-tbody>tr .ant-table-cell-row-hover {
  background: #dadada !important;
}

.ant-radio-button-checked {
  background: #0052D9 !important;
  border-radius: 6px !important;
}

.ant-radio-button,
.ant-radio-button-wrapper {
  border-radius: 6px !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff !important;
}

.ant-form-item-label>label {
  color: #000 !important;
}

.ant-select-selector,
.ant-picker-range,
.ant-input,
.ant-input-number-input {
  border-color: #b4b1b1 !important;
}

.ant-select-selection-placeholder {
  color: #827d7d !important;
}

input::-webkit-input-placeholder {
  color: #827d7d !important;
}

input:disabled {
  color: rgba(0, 0, 0, 0.55) !important;
  // border: none !important;
}

.ant-picker {
  border-color: #b4b1b1 !important;
}

.detailHtml img,
.detailHtml image {
  max-width: 100%;
}

.detailHtmlBox p {
  word-break: break-all;
}

.flex {
  display: flex;

}

.flex_justify_content_between {
  justify-content: space-between;
}

.flex_justify_content_center {
  justify-content: center;
}

.flex_justify_content_end {
  justify-content: end;
}

.text_align_center {
  text-align: center;
}
@primary-color: #0052D9;@layout-body-background: #f1f2f5;